import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "https://localhost:3001" : "https://dev-middleware.svasthacircle.com",
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
    },
});

// wrapper function to handle all methods
const apiRequest = (method, url, data) => {
    return axiosInstance.request({
        method,
        url,
        data,
    });
};

// set Token for APIs
export const setToken = (token) => {
    axiosInstance.defaults.headers.common["Authorization"] = token;
};

// set Error Handler for API error;
export const setErrorHandler = (handler) => {
    axiosInstance.interceptors.response.use(
        (response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        (error) => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            const message = error.response?.data.error || error.message;
            handler(message);
            return false;
        }
    );
};

export default apiRequest;
