import DataContext from "./context/context";
import { RouterProvider } from "react-router-dom";
import routes from "./routes/routes";
import { useEffect, useState } from "react";
import { setErrorHandler, setToken } from "./utils/api";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

function App() {
    const [user, setUser] = useState({});
    const context = {
        user,
        setUser,
    };

    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Add a response interceptor
    const apiErrorHandler = (message) => {
        setErrorMessage(message);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (user.token) {
            setToken(user.token);
        }
    }, [user.token]);

    useEffect(() => {
        setErrorHandler(apiErrorHandler);
    }, []);

    const Loader = () => "loading...";

    return (
        <DataContext.Provider value={context}>
            <RouterProvider router={routes} fallbackElement={<Loader />} />
            <Dialog open={showModal} onClose={handleClose} aria-labelledby='error-title'>
                <DialogTitle id='error-title'>{"Error accessing data"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </DataContext.Provider>
    );
}

export default App;
