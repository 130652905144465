import React from "react";
const Pages = {
    Home: React.lazy(() => import("./Home")),
    About: React.lazy(() => import("./About")),
    Login: React.lazy(() => import("./Login")),
    RegisterHospital: React.lazy(() => import("./RegisterHospital")),
    RegisterDoctor: React.lazy(() => import("./RegisterDoctor")),
};

export default Pages;
