import { createBrowserRouter, Link } from "react-router-dom";
import Pages from "@pages";
import { Suspense } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

function NoMatch() {
    return (
        <div className='text-center mt-5'>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to='/'>Go to the home page</Link>
            </p>
        </div>
    );
}

function Loader() {
    return (
        <Backdrop sx={{ color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress color='inherit' />
        </Backdrop>
    );
}

const routes = createBrowserRouter([
    {
        path: "/",
        element: (
            <Suspense fallback={<Loader />}>
                <Pages.Home />
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<Loader />}>
                        <Pages.Login />
                    </Suspense>
                ),
            },
            {
                path: "register-hospital",
                element: (
                    <Suspense fallback={<Loader />}>
                        <Pages.RegisterHospital />
                    </Suspense>
                ),
            },
            {
                path: "register-doctor",
                element: (
                    <Suspense fallback={<Loader />}>
                        <Pages.RegisterDoctor />
                    </Suspense>
                ),
            },
            {
                path: "*",
                element: (
                    <Suspense fallback={<Loader />}>
                        <NoMatch />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: "/dashboard",
        element: (
            <Suspense fallback={<Loader />}>
                <Pages.About />
            </Suspense>
        ),
    },
    {
        path: "*",
        element: (
            <Suspense fallback={<Loader />}>
                <NoMatch />
            </Suspense>
        ),
    },
]);

export default routes;
